<template>
  <div class="home">
    <div v-if="loading">
      <div style="height: 10rem;width: 100vw;display: flex;">
        <div style="width: 20rem;text-align: left;margin-top: 2rem;margin-left: 1rem;color: white;">
          <span style="display: inline-block;width: 250px;font-size: 0.9rem;font-weight: 600;">幼儿园入(出)园接送信息登记</span>
          <span style="display: inline-block;width: 250px;font-size: 12px;transform: scale(0.7);margin-left: -2.4rem;">
            Kindergarten in (out) transfer information registration</span>
        </div>
        <img src="../assets/img/person.png" width="350rem" height="180rem"
          style="background-size: cover;margin-top:2.5rem;margin-left: -10rem;position: relative;z-index: -1;left: 1rem;" />
      </div>
      <div class="contain" style="">
        <div style="width: 80%;background-color: white;border-radius: 10px;padding-bottom: 3rem;padding-top: 2rem;padding-left: 3rem;">
          <span style="font-size: 0.8rem;color: #256ddb;font-weight: bold;padding-right: 0.8rem;">请选择类型</span>
          <el-radio-group v-model="radio" @change="changeRadio" :disabled="radioDisabled">
            <el-radio :label="2" style="color: #000000;">送孩子</el-radio>
            <el-radio :label="1" style="color: #000000;">接孩子</el-radio>
          </el-radio-group>
          <div style="padding-top: 2rem;" class="flex">
            <span
              style="font-size: 0.8rem;color: #256ddb;font-weight: bold;padding-right: 0.8rem;text-align: left;">请选择孩子</span>
            <!-- <el-select v-model="value" placeholder="请选择" size="mini" style="width: 10em;" @change="selectChange">
              <el-option v-for="item in options" :key="item.value" :label="item.children.name" :value="item.children.name">
              </el-option>
            </el-select> -->
            <el-checkbox-group v-model="checkList" style="width:100%" @change="changeCheck">
              <el-checkbox :label="item.children.name" border size="medium" style="margin-top: 1rem;width: 7rem;"
                v-for="item in options"></el-checkbox>
            </el-checkbox-group>
          </div>
          <div style="padding-top: 4rem;">
            <el-button type="primary" style="width: 10rem;margin-left: 3.5rem;" round @click="handleSubmit()"
              :disabled="disabled">提交
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div style="width: 100vw;display: flex;justify-content: center;align-items: center;">
        <img src="../assets/img/bg.png" width="100%" style="height: 200vh;position: absolute;right: 0.1rem;" />
        <img src="../assets/img/loading.gif" width="100%" style="position: absolute;top: 30%;" />
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        radio: 1,
        url: '',
        options: [],
        value: '',
        childrenData: [],
        currentChild: '',
        parentData: '',
        disabled: false,
        loading: true,
        radioDisabled: false,
        checkList: []//孩子多选框
      }
    },
    created() {
      this.disabled = false
      this.url = this.$route.query.accessToken
      this.getUser()
      this.getHour()

    },
    mounted() {
    },
    methods: {
      //获取当前时间,并判断6-12点送孩子,12-19点接孩子,其他时间提示非接送时间，不能提交
      getHour() {
        var myDate = new Date(); //实例一个时间对象；
        let h = 12
        if (h >= 6 && h <= 12) {
          this.radio = 2
        } else if (h > 12 && h <= 19) {
          this.radio = 1
        } else {
          this.radioDisabled = true
          this.disabled = true
          this.$message.error("非接送时间,不能提交")
        }
      },
      //选择框事件
      changeCheck(e) {
        this.checkList = e
      },
      async getUser() {
        const res = await this.http.post("/show/match", {
          accessToken: this.url
        })
        if (res.code == 200) {
          this.options = res.childrenData
          this.childrenData = res.childrenData
          this.parentData = res.parentData
          console.log(this.options)
          if (this.options.length == 1) {
            this.checkList.push(this.options[0].children.name)
            console.log(this.checkList)
          }
        }
        //存储家长的信息
        window.sessionStorage.setItem("parentData", JSON.stringify(res.parentData))
        // 判断如果存在家长则可以进入否则跳转到其他页面
        if (res.code == 400) {
          this.loading=false
          setTimeout(()=>{
            this.loading=true
            this.$message.error("暂未查找到你的信息，请填写资料")
            this.$router.push({ name: "subFail" })
          },2000)
        }
      },
      // //下拉框选择后遍历数组查找到包含当前选择值得这条数据
      // selectChange(name) {
      //   //遍历数组返回只存在那条数据的数组，其他数据为空
      //   let obj = this.childrenData.map(e => {
      //     if (e.children.name === name) {
      //       return e
      //     }
      //   })
      //   //遍历有空的数组，把空的去掉，返回最后需要的那一条数据
      //   let obj2 = obj.map(e => {
      //     if (e == undefined) {
      //       console.log(e)
      //     } else {
      //       console.log(e)
      //       this.currentChild = e
      //     }
      //   })
      // },
      //单选框事件
      changeRadio(e) {
        this.radio = e
      },
      async handleSubmit() {
        var arr = []
        //利用当前选中的值去作为条件再去遍历所有的小孩的数组，找出当前的小孩的写好参数再放进新建的数组
        this.checkList.map(e => {
          this.options.map(item => {
            if (item.children.name == e) {
              let obj = {
                nursery_id: item.nursery.id,
                name: item.children.name,
                userid: item.nursery.par_userid,
                operate: this.radio,
                class_id: item.class.id,
                children_id: item.children.id,
                parent_name: this.parentData.name,
                parent_phone: this.parentData.phone,
                class: item.class.name,
                nursery: item.nursery.name
              }
              arr.push(obj)
            }
          })
        })
        if (this.radio != '' && this.checkList.length != 0) {
          this.disabled = true
          this.loading = false
          //存储当前孩子的信息
          window.sessionStorage.setItem("childrenData", JSON.stringify(arr))
          const res = await this.http.post("show/sucCommit", {
            message: JSON.stringify(arr)
          })
          if (res.code == 400) {
            this.$router.push({ name: 'load' })
          } else if (res.code == 200) {
            this.$router.push({ name: "message" })
          }
        } else {
          this.$message.error("请填写全部资料")
        }

      }
    },
  }
</script>

<style scoped="true">
  .home {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    background-image: url(../assets/img/bg.png);
    position: fixed;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
  }

  .contain {
    align-items: flex-end;
    text-align: left;
    justify-content: center;
    display: flex;
  }
</style>
